var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-banner',[_c('div',{staticClass:"d-flex justify-space-between pr-4"},[_c('p',{staticClass:"text-h5 font-weight-medium mb-0 d-flex align-center"},[_vm._v(" Historial de Pagos ")])])]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.data.headers,"items":_vm.data.data,"item-per-page":5,"search":_vm.search,"loading":_vm.data.load,"loading-text":"Cargando datos..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v(" Lista de Pagos ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.total)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){_vm.selectPayment(item);
                        _vm.modalDetail();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-clipboard-list-outline ")])]}}],null,true)},[_c('span',[_vm._v("Detalles de la compra")])])]]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Recargar ")])]},proxy:true}],null,true)})],1)],1)],1)],1),_c('DetailPayment',{ref:"mdlDetail",attrs:{"payment":_vm.slcPayment}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }