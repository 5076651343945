<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
          Historial de Pagos
        </p>
      </div>
    </v-banner>

    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              :headers="data.headers"
              :items="data.data"
              :item-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Lista de Pagos
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-toolbar>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                $ {{ item.total }}
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <template>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="
                          selectPayment(item);
                          modalDetail();
                        "
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-clipboard-list-outline
                      </v-icon>
                    </template>
                    <span>Detalles de la compra</span>
                  </v-tooltip>
                </template>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modal -->
    <DetailPayment ref="mdlDetail" :payment="slcPayment"></DetailPayment>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DetailPayment from "./DetailPayment.vue";
export default {
  name: "PaymentHistory",
  components: { DetailPayment },
  data: () => ({
    data: {
      load: true,
      headers: [
        { text: "Fecha y hora", value: "date_hour" },
        { text: "Autorización", value: "authorization_code" },
        { text: "Transacción", value: "transaction" },
        { text: "Total Pagado", value: "total" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      data: [],
    },
    search: "",
    slcPayment: {},
  }),
  mounted() {
    this.getHistory();
  },
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    getHistory: function() {
      this.data.load = true;
      let id = this.student.id_student;
      this.$http
        .get(this.api + "pay/history")
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },
    selectPayment: function(payment) {
      this.slcPayment = payment;
    },
    modalDetail: function() {
      this.$refs.mdlDetail.changeModal();
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      student: "Student/getStudent",
    }),
  },
};
</script>

<style></style>
