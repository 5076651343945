<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Detalle de la compra</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container class="pb-0">
          <v-row>
            <v-col cols="12" sm="12" md="6" class="black--text pb-0">
              <p>
                <span class="font-weight-bold">Fecha y hora: </span>
                <span> {{ payment.date_hour }} </span>
              </p>
              <p>
                <span class="font-weight-bold">Código de Autorización: </span>
                <span> {{ payment.authorization_code }} </span>
              </p>
              <p>
                <span class="font-weight-bold">Transacción: </span>
                <span> {{ payment.transaction }} </span>
              </p>
              <p class="mb-0">
                <span class="font-weight-bold">Total: </span
                ><span>$ {{ payment.total }} </span>
              </p>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="pb-0">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left azul white--text">
                        Concepto
                      </th>
                      <th class="text-left azul white--text">
                        Monto
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(pago, index) in pagos" :key="index">
                      <td>
                        {{ pago.concepto }}
                      </td>
                      <td>$ {{ parseFloat(pago.subtotal).toFixed(2) }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <b>Subtotal</b>
                      </td>
                      <td>$ {{ totalPagar }}</td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="dialog = false">
          cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DetailPayment",
  data() {
    return {
      detail: {},
      pagos: {},
      dialog: false,
    };
  },
  props: { payment: Object },
  methods: {
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    getDetail: function() {
      this.detail = {};
      let id = this.payment.id_master_pay;
      this.$http.get(this.api + "pay/history/" + id).then(({ data }) => {
        this.detail = data;
        this.pagos = data;
      });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
    totalPagar: function() {
      let suma = 0.0;
      if (this.pagos.length > 0) {
        this.pagos.forEach((pago) => {
          suma += parseFloat(pago.subtotal);
        });
      }
      let respuesta = parseFloat(suma).toFixed(2);
      return respuesta;
    },
  },
  watch: {
    payment(val) {
      this.getDetail();
    },
  },
};
</script>

<style></style>
